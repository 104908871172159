/* eslint-disable jsx-a11y/alt-text */
import { useAuth0 } from '@auth0/auth0-react'
import '@fontsource/metropolis' // Defaults to weight 400.
import { AllLocaleData, AllMessages } from '@lingui/core'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { OpenAPI } from '@om1/joint-insights-api/codegen/client/core/OpenAPI'
import { Auth0PlatformProvider } from '@om1/platform-authentication'
import { platformConfigActions } from '@om1/platform-config/state'
import { NotificationState } from '@om1/platform-notifications'
import { createTrackingMiddleware, TrackingProvider, useAnalytics } from '@om1/platform-tracking'
import { createHelpDialog } from '@om1/platform-ui-kit/src/components/sidebar/HelpDialog'
import { createTimeoutDialog } from '@om1/platform-ui-kit/src/components/sidebar/TimeoutDialog'
import { platformTheme } from '@om1/platform-ui-kit/src/theme'
import { withConfig } from '@om1/platform-utils'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { en, es } from 'make-plural'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React from 'react'
import { Provider, useSelector } from 'react-redux'
import { addMiddleware } from 'redux-dynamic-middlewares'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import packageJson from '../package.json'
import { config, ConfigInterface } from './Config'
import { AppStore, createAppStore, createReducer, createRootSaga, JointInsightsState } from './joint-insights-state'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { createLandingPageComponent } from './pages/LandingPage'
import LanguageProvider from './shared/providers/internationalization/LanguageProvider'

function JointInsightsApp() {
    // create top level react contexts (router history, saga middleware, redux store etc)
    const history = createBrowserHistory()
    const appReducer = createReducer(history)
    const sagaMiddleware = createSagaMiddleware()
    const store = createAppStore(appReducer, sagaMiddleware, routerMiddleware(history))

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Auth0PlatformProvider>
                    <SnackbarProvider maxSnack={3}>
                        <TrackingProvider>
                            <ConfiguredApp sagaMiddleware={sagaMiddleware} store={store} />
                        </TrackingProvider>
                    </SnackbarProvider>
                </Auth0PlatformProvider>
            </ConnectedRouter>
        </Provider>
    )
}

interface ConfiguredAppComponentProps {
    config: ConfigInterface
    sagaMiddleware: SagaMiddleware<object>
    store: AppStore
}

const ConfiguredAppComponent: React.FunctionComponent<ConfiguredAppComponentProps> = ({
    config,
    sagaMiddleware,
    store
}: ConfiguredAppComponentProps) => {
    let { jointInsightsApiUrl, writeKey, buildNumber, environmentTag } = config
    const { isAuthenticated, isLoading } = useAuth0()
    // eslint-disable-next-line string-to-lingui/text-restrictions
    OpenAPI.BASE = jointInsightsApiUrl.replace('/joint-insights', '')

    const { enqueueSnackbar } = useSnackbar()
    const analytics = useAnalytics(writeKey)

    // create tracking middleware and add to redux
    const trackingMiddleware = createTrackingMiddleware(analytics)
    addMiddleware(trackingMiddleware)

    // start redux-sagas
    const rootsaga = createRootSaga(enqueueSnackbar, analytics)
    const [firstLoad, setFirstLoad] = React.useState(true)
    if (rootsaga && firstLoad) {
        sagaMiddleware.run(rootsaga)
        setFirstLoad(false)
    }

    // manages the auth0 authentication status, issues actions based on changes
    if (isAuthenticated && !isLoading) {
        // manages versioning and environment tags
        store.dispatch(platformConfigActions.setBuildNumber(buildNumber))
        store.dispatch(platformConfigActions.setEnvironmentTag(environmentTag))
        store.dispatch(platformConfigActions.setSemanticVersion(packageJson.version))
    }

    // establish translations
    const messages: AllMessages = {
        en: enMessages,
        es: esMessages
    }
    const plurals: AllLocaleData = {
        en: { plurals: en },
        es: { plurals: es }
    }

    // create landing page (base of the application, main routing, top nav etc)
    const LandingPage = createLandingPageComponent()

    const HelpDialog = createHelpDialog<{ notification: NotificationState }>()
    const TimeoutDialog = createTimeoutDialog<{ notification: NotificationState }>()

    const selectLocale = (state: JointInsightsState) => state.language.locale
    const locale = useSelector(selectLocale)

    return (
        <React.StrictMode>
            <LanguageProvider language={locale} messages={messages} plurals={plurals}>
                <ThemeProvider theme={platformTheme}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100dvh',
                            height: '100dvh', // Force full viewport height
                            overflow: 'hidden' // Prevent scrolling on the main container
                        }}
                    >
                        <main
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden' // Allow scrolling in the main content area if needed
                            }}
                        >
                            <CssBaseline />
                            <HelpDialog />
                            <TimeoutDialog />
                            <LandingPage />
                        </main>
                    </div>
                </ThemeProvider>
            </LanguageProvider>
        </React.StrictMode>
    )
}

const ConfiguredApp = withConfig<ConfiguredAppComponentProps>(ConfiguredAppComponent, config)

export default JointInsightsApp
